import React, { useRef, useState } from 'react'
import { Box, IconButton, Dialog } from '@material-ui/core'
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import About from '../components/About'
import Gallery from '../components/Gallery'
import Testimonials from '../components/Testimonials'
import Footer from '../components/Footer'
import HeroesBg from '../../static/images/heroes-bg.jpg'
import { SEO } from '../components/seo'
import Heroes from '../components/Heroes'
import { graphql } from 'gatsby'
import { Close } from '@material-ui/icons'
import { usePopupModal } from '../hooks/use-popup-modal'
import { useMount, useClickAway } from 'react-use'
import { makeStyles } from '@material-ui/styles'

const useModalStyles = makeStyles({
  paper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
})

export default function Home({ data }) {
  const modalClasses = useModalStyles()
  const {
    datoCmsHomePage: datas,
    allDatoCmsTestimonial: testimonialData,
  } = data
  const bannerImage = datas.banner[0]?.url ?? HeroesBg
  const popupImage = usePopupModal()
  const [isPopupShow, setIsPopupShow] = useState(false)
  const modalRef = useRef(null)
  useMount(() => {
    setIsPopupShow(popupImage.enable)
    //
    // window.dataLayer = window.dataLayer || []
    // function gtag() {
    //   dataLayer.push(arguments)
    // }
    // gtag('js', new Date())

    // gtag('config', 'AW-10978330141')
  })
  useClickAway(modalRef, () => {
    if (isPopupShow) {
      closePopup()
    }
  })
  function closePopup() {
    setIsPopupShow(false)
  }

  return (
    <>
      <SEO />

      <Layout>
        <Navbar />
        <Heroes
          isHomePage
          minHeight="100vh"
          homeHeading={datas.headingBanner}
          homeDesc={datas.deskripsiBanner}
          banner={datas.banner.url}
          button1={{ text: datas.buttonText1, link: datas.buttonLink1 }}
          button2={{ text: datas.buttonText2, link: datas.buttonLink2 }}
          imgBackground={bannerImage}
        />
        <Box id="about">
          <About
            title={datas.titleAbout}
            heading={datas.headingAbout}
            deskripsi={datas.deskripsiAbout}
            gambar={datas.gambarAbout.url}
          />
        </Box>
        <Box id="gallery">
          <Gallery
            title={datas.titleGallery}
            heading={datas.headingGallery}
            deskripsi={datas.deskripsiGallery}
            gambar={[
              datas.gallery1.url,
              datas.gallery2.url,
              datas.gallery3.url,
              datas.gallery4.url,
              datas.gallery5.url,
              datas.gallery6.url,
            ]}
            footer={{
              title: datas.footerGallery,
              text: datas.textButtonFooterGallery,
              link: datas.linkButtonFooterGallery,
            }}
          />
        </Box>
        <Box id="testimonials">
          <Testimonials
            heading={{
              title: datas.titleTestimonial,
              head: datas.headingTestimonial,
              desc: datas.deskripsiTestimonial,
            }}
            dataTesti={testimonialData.nodes}
          />
        </Box>
        <Footer
          heading={datas.headingFooter}
          sosmed={[
            { text: datas.textFooter1, link: datas.linkFooter1 },
            { text: datas.textFooter2, link: datas.linkFooter2 },
            { text: datas.textFooter3, link: datas.linkFooter3 },
          ]}
          copyright={datas.copyright}
        />

        {popupImage.enable && (
          <Dialog
            boxShadow={0}
            maxWidth={'sm'}
            open={isPopupShow}
            classes={{ paper: modalClasses.paper }}
          >
            <div ref={modalRef}>
              <Box position={'relative'} p={4} color={'white'}>
                <IconButton
                  onClick={closePopup}
                  color={'inherit'}
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    marginBottom: '1rem',
                  }}
                >
                  <Close />
                </IconButton>
                <a href={popupImage.link}>
                  <img
                    style={{ maxWidth: '100%' }}
                    alt={popupImage.image.alt}
                    src={popupImage.image.url}
                  />
                </a>
              </Box>
            </div>
          </Dialog>
        )}
      </Layout>
    </>
  )
}

export const query = graphql`
  query MyQuery {
    datoCmsHomePage {
      banner {
        url
      }
      headingBanner
      deskripsiBanner
      buttonText1
      buttonLink1
      buttonText2
      buttonLink2
      gambarAbout {
        url
      }
      titleAbout
      headingAbout
      deskripsiAbout
      titleGallery
      headingGallery
      deskripsiGallery
      gallery1 {
        url
      }
      gallery2 {
        url
      }
      gallery3 {
        url
      }
      gallery4 {
        url
      }
      gallery5 {
        url
      }
      gallery6 {
        url
      }
      footerGallery
      textButtonFooterGallery
      linkButtonFooterGallery
      titleTestimonial
      headingTestimonial
      deskripsiTestimonial
      headingFooter
      textFooter1
      linkFooter1
      textFooter2
      linkFooter2
      textFooter3
      linkFooter3
      copyright
    }
    allDatoCmsTestimonial(sort: { order: DESC, fields: date }) {
      nodes {
        name
        date(formatString: "DD MMM YYYY")
        message
      }
    }
  }
`
